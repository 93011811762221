import React, { Component } from 'react';
import numeral from 'numeral';
import { IoIosCheckmark as SaveIcon } from 'react-icons/io'

import '../styles/quotationedit.scss';

class Home extends Component {
    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputChange222 = this.handleInputChange222.bind(this);
        this.handleInputChange555 = this.handleInputChange555.bind(this);

        this.state = {
            currency : '€',
            invoiceAddress : '',
            deliveryAddress : '',
            q222 : 0,
            q555 : 0,
            p222 : this.prices222(0),
            p555 : this.prices555(0)
        };
    }

    prices222 = (n) => {
        if (n > 9) return 100;
        if (n > 8) return 115;
        if (n > 7) return 120;
        if (n > 6) return 125;
        if (n > 5) return 130;
        if (n > 4) return 135;
        if (n > 3) return 140;
        if (n > 2) return 150;
        if (n > 1) return 155;
        if (n > 0) return 160;
        return 160;
    }
     
    prices555 = (n) => {
        return this.prices222(n) * 2.3;
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({ [name] : value });
      }

      handleInputChange222(event) {
        const target = event.target;
        
        let q222 = 1.0 * target.value;
        let p222 = this.prices222(q222);

        this.setState({ q222 : q222, p222 : p222 });
      }


      handleInputChange555(event) {
        const target = event.target;
        
        let q555 = 1.0 * target.value;
        let p555 = this.prices555(q555);

        this.setState({ q555 : q555, p555 : p555 });
      }

    render() {
        numeral.locale('nl');

        return (
        <div className="container">
            <form className="quotationEditor" method="post" action="https://aurashop.24readings.com/pay/">
                <div className="contactData">
                    <fieldset>
                        <label htmlFor="name">Name</label>
                        <input type="name" name="name" value={this.state.name} onChange={this.handleInputChange} />
                    </fieldset>
                    <fieldset>
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input type="tel" name="phoneNumber" value={this.state.phoneNumber} onChange={this.handleInputChange} />
                    </fieldset>                    
                    <fieldset>
                        <label htmlFor="emailAddress">Email address</label>
                        <input type="email" name="emailAddress" value={this.state.emailAddress} onChange={this.handleInputChange} />
                    </fieldset>                    
                </div>

                <div className="addresses">                    
                    <fieldset>
                        <label htmlFor="invoiceAddress">Invoice address</label>
                        <textarea id="invoiceAddress" name="invoiceAddress" value={this.state.invoiceAddress} onChange={this.handleInputChange} />                
                    </fieldset>

                    <fieldset>
                        <label htmlFor="deliveryAddress">Delivery address</label>
                        <textarea id="deliveryAddress" placeholder="Same as invoice address" name="deliveryAddress" value={this.state.deliveryAddress} onChange={this.handleInputChange} />                
                    </fieldset>
                </div>

                <ul className="quotationLines">
                    <li>
                        <div className="itemLine labels">
                            <div className="description">
                                <label>Description</label>
                            </div>
                            <div className="quantity">
                                <label>Quantity</label>
                            </div>
                            <div className="pricePer">
                                <label>Price</label>
                            </div>
                            <div className="priceTotal">
                                <label>Totalprice</label>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="itemLine">
                            <figure><img alt="222" src={process.env.PUBLIC_URL + '/img/warrantymod.jpg'} /></figure>

                            <div className="description">
                                <label>Cartouche &bull; Warrantymodule; 222 exposures</label>
                            </div>
                            <div className="quantity">
                                <input type="text" value={this.state.q222} name="q222" onChange={this.handleInputChange222} />
                            </div>

                            <div className="pricePer">
                            {this.state.currency}{numeral(1 * this.state.p222).format("0.00")}                               
                            </div>
                        
                            <div className="priceTotal">
                                {this.state.currency}{numeral(1 * this.state.q222 * this.state.p222).format("0.00")}                                
                            </div>                            
                        </div>
                    </li>
                    <li>
                        <div className="itemLine">
                            <figure><img alt="555" src={process.env.PUBLIC_URL + '/img/warrantymod.jpg'} /></figure>

                            <div className="description">
                                <label>Cartouche &bull; Warrantymodule; 555 exposures</label>
                            </div>
                            <div className="quantity">
                                <input type="text" value={this.state.q555} name="q555" onChange={this.handleInputChange555} />
                            </div>

                            <div className="pricePer">
                            {this.state.currency}{numeral(1 * this.state.p555).format("0.00")}                               
                            </div>
                        
                            <div className="priceTotal">
                                {this.state.currency}{numeral(1 * this.state.q555 * this.state.p555).format("0.00")}                                
                            </div>                            
                        </div>
                    </li>
                    <li>
                        <div className="itemLine">
                            <div className="space"></div>
                        
                            <div className="priceTotal">
                                {this.state.currency}{numeral(1 * this.state.q555 * this.state.p555 + 1 * this.state.q222 * this.state.p222).format("0.00")}
                                <input type="hidden" name="totalAmount" value={1 * this.state.q555 * this.state.p555 + 1 * this.state.q222 * this.state.p222} />
                            </div>                            
                        </div>
                    </li>
                </ul>

                <div className="saveQuotationForm">
                    <button onClick={this.saveQuotaion}>
                        <SaveIcon size="2em" /> Pay now
                    </button>                    
                </div>
            </form>
        </div>);
    }
}

export default Home;