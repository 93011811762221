import React, { Component } from 'react';

class ThankYou extends Component {
    render() {
        return (<div class="container">
            <hgroup className="stepHeader">
                <h2>Bedankt</h2>
            </hgroup>
            
            <p>Bedankt voor uw bestelling. U krijgt snel een mail met uw factuur.</p></div>);
    }
}

export default ThankYou;