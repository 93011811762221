import './styles/main.scss'

import React from 'react';
import ReactDOM from 'react-dom';

import { Route, Switch, Redirect } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App';
import Home from './components/home';
import ThankYou from './components/thankyou';

import { toast } from 'react-toastify';
import numeral from 'numeral';

const locale = 'nl';

toast.configure();

numeral.register('locale', 'nl', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal : function (number) {
        return number === 1 ? 'ste' : 'de';
    },
    currency: {
        symbol: '€'
    }
});

const RedirectToHome = () => (
    <Redirect to="/" />
)

ReactDOM.render(
    <Router>        
      <App>            
          <Switch>
              <Route name="home" exact path='/' component={Home} />
              <Route name="home" path='/thankyou' component={ThankYou} />

              <Route component={RedirectToHome} />
          </Switch>            
      </App>        
    </Router>, document.getElementById('root'));