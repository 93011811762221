import React, { Component } from 'react';

class App extends Component {

    render() {
        return (
            <React.Fragment>
                <header>
                    <img alt="logo" src={process.env.PUBLIC_URL + '/img/logo.png'} className="no-print" />
                    <img alt="logo" src={process.env.PUBLIC_URL + '/img/logo.png'} className="print-only" />    
                    {this.props.title || 'Auracam orderform' }                
                </header>

                <main>
                    {this.props.children}
                </main>

                <footer>
                    <div className="container">
                        <div className="sloganColumn">
                            <h4>24Readings &bull; Pure &bull; Personal &bull; Profesional</h4>
                            <p>
                                Your Aurocam supplies delivered.
                            </p>
                        </div>

                        <div className="contactColumn">
                            <p>
                                <span className="telephone"><a href="tel:+31765300204">+31 (0) 76 5300 204</a></span>                     
                                <span className="telephone"><a href="mailto:info@24readings.com">info@24readings.com</a></span>
                            </p>
                            <p>
                                Achterom 49<br />
                                4811LS Breda<br />
                                Netherlands
                            </p>
                        </div>   
                    </div>         
                </footer>
            </React.Fragment>
        );
    }

}

export default App;
